@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RelatedBuiltProducts {
    &-Wrapper {
        h2 {
            margin: 24px 0;
        }

        .ProductCard {
            &-Content {
                @include tabletAndAbove {
                    background: transparent;
                    margin-block-start: -50px;
                }
            }

            &-Discount {
                background: $white;
                inset-block-end: 12px;
                inset-inline-start: 6px;

                @include tabletAndAbove {
                    inset-block-end: 50px;
                    inset-inline-start: 12px;
                    z-index: 11;
                }
            }

            &-PriceWrapper {
                z-index: 11;
                width: auto;
                justify-content: flex-start;
                flex-direction: row;
            }

            &-Price {
                width: auto;
                background: $white;

                @include desktop {
                    padding: 0 16px;
                }
            }

            &-Picture {
                padding: 0;

                .Image-Image {
                    position: static;
                    min-height: 156px;
                    object-fit: cover;

                    @include mobile {
                        min-height: 156px;
                        object-fit: cover;
                    }
                }
            }

            border: 1px solid transparent;

            &:hover {
                box-shadow: none;
            }

            &-ProductActions {
                @include mobile {
                    display: none;
                }
            }

            .ProductPrice {
                &-HighPrice {
                    @include mobile {
                        font-size: $fs-xxxs;
                    }
                }

                &-PriceValue {
                    @include mobile {
                        font-size: $fs-xs;
                    }
                }
            }

            .ProductCard {
                &-VisibleOnHover {
                    inset-inline-start: -1px;
                    padding-inline-start: 15px;

                    &::before {
                        display: none;
                    }
                }

                &-Variants {
                    display: none;
                }
            }

            &-Label {
                inset-block-start: 8px;
                inset-inline-start: 8px;
            }
        }

        .SliderCounterNav {
            margin-block-end: 32px;
        }

        .slick-slider {
            margin-inline-start: -16px;
            margin-inline-end: -16px;

            @include mobile {
                margin-inline-start: -8px;
                margin-inline-end: -8px;
            }
        }

        .slick-slide {
            @include mobile {
                padding: 0 8px;
            }
        }

        .slick-list {
            overflow: visible;
            z-index: 1;
        }

        /* stylelint-disable-next-line */
        .slick-prev,
        .slick-next {
            border: 1px solid #E8E9E9;
            background-color: $white;
            height: 64px;
            width: 64px;
            padding: 20px;
            z-index: 2;
            inset-block-start: 40%;
        }

        .slick-prev {
            inset-inline-start: 0px;
        }

        .slick-next {
            inset-inline-end: 0px;
        }
    }

    &-Box {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(2, 1fr);

        @include mobile {
            grid-gap: 14px;
        }
    }
}
