@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AskAboutProduct {
    padding-block-end: 32px;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 32px 60px;
        padding: 0 0 48px;
    }

    &-Heading {
        margin: 0 0 24px;
        grid-column: span 2;
    }

    &-ProductName {
        font-weight: $fw-medium;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    &-ProductContentWrapper {
        display: grid;
        grid-template-columns: 92px 1fr;
        gap: 4px;
        margin-block-end: 24px;


        @include desktop {
            grid-template-columns: 128px 1fr;
            gap: 16px;
            margin-block-end: 0;
        }
    }

    &-ProductInfoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ProductPrice {
        display: flex;
        flex-direction: column;

        &-HighPrice {
            order: -1;
        }

        &-PriceValue {
            margin-block-start: 0;

            @include desktop {
                font-size: $fs-xl;
                line-height: 30px;
            }
        }
    }

    &-FormWrapper {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: max-content;
            grid-column-gap: 32px;
            grid-row-gap: 16px;
        }

        >div {
            @include desktop {
                &:nth-of-type(1) {
                    grid-area: 1 / 1 / 2 / 2;
                }

                &:nth-of-type(2) {
                    grid-area: 2 / 1 / 3 / 2;
                }

                &:nth-of-type(3) {
                    grid-area: 3 / 1 / 4 / 2;
                }

                &:nth-of-type(4) {
                    grid-area: 1 / 2 / 3 / 3;
                }
            }

            .Field {
                @include desktop {
                    margin: 0;
                }
            }
        }

        input {
            padding: 12px 16px;
            width: 100%;
        }

        button {
            padding: 11px 32px;
            height: 48px;

            @include mobile {
                margin-block-start: 16px;
            }

            @include desktop {
                grid-area: 3 / 2 / 3 / 3;
            }
        }

        .Consents {
            @include desktop {
                grid-area: 4 / 1 / 4 / 3;
            }

            p {
                padding-inline-start: 26px;
                margin-block-end: 0;
            }

            &-Field {
                .input-control {
                    min-height: 18px;
                    margin-block-start: 3px;
                }

                &.Consents-Field {
                    @include mobile {
                        margin: 0;
                    }
                }
            }

            &-FieldWrapper {
                @include desktop {
                    margin: 0;
                }

                p {
                    padding-inline-start: 0;
                }
            }

            >div {
                padding: 6px 0;
            }
        }
    }

    textarea {
        width: 100%;
        height: 110px;
        font-size: $fs-s;

        &::placeholder {
            color: $black;
        }

        @include mobile {
            margin-block-end: 16px;
        }
    }
}
