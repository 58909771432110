@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewForm {
    &-Wrapper {
        display: block;
    }

    &-Title {
        display: none;
    }

    &-Legend {
        font-weight: $fw-light;
        font-size: $fs-s;
        line-height: 24px;

        @include mobile {
            margin-block-end: 12px;
        }
    }

    .ReviewStar {
        font-size: $fs-xl;
        width: auto;
        min-width: unset;
        text-shadow: -1px 0 $gold,
            0 1px $gold,
            1px 0 $gold,
            0 -1px $gold;

        &:checked,
        &:hover,
        &[checked],
        &:checked~input,
        &:hover~input,
        &[checked]~input {
            color: $gold;
        }
    }

    .Field {
        &-Label {
            font-weight: $fw-light;
        }

        &-CheckboxLabel {
            font-size: $fs-xs;
            font-weight: $fw-medium;
        }
    }

    &-Content {
        input {
            font-size: $fs-s;
        }

        [type='checkbox'],
        [type='radio'] {

            /* stylelint-disable-next-line */
            &+.input-control,
            &:hover+.input-control,
            &:checked+.input-control,
            &[disabled]:checked+.input-control {
                border: 1px solid $gray-light;
            }

            &+.input-control {
                width: 20px;
                height: 20px;
            }
        }

        textarea {
            min-height: 96px;
            font-size: $fs-s;

            &::-webkit-input-placeholder {
                color: $black;
                font-size: $fs-s;
                font-weight: $fw-light;
            }
        }
    }

    &-Rating {
        display: flex;
        justify-content: flex-end;
        gap: 4px;
    }

    .ProductReviewForm {
        &-Button {
            width: 100%;
            margin: 16px 0 0;
        }
    }
}
