@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductHeader {
    grid-column: 1/-1;

    @include flex($justify: space-between);

    h1 {
        @include tabletAndAbove {
            width: 70%;
        }
    }

    &-Title {
        width: 100%;
    }

    &-ActionButtons {
        margin-block-end: 40px;

        .ProductCompareButton {
            svg {
                fill: $color-primary;
            }
        }

        .ProductWishlistButton {
            svg {
                stroke: $color-primary;
            }
        }

        div {
            margin: 0 18px;
        }
    }
}
