@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AmastyBundleProductsList {
    &-showMore {
        width: 100%;
        height: 300px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -93%, #FFF 65%);
        position: absolute;
        inset-block-end: 0;
        z-index: 20;

        @include flex($justify: center);

        &_isFirstElementHidden {
            @include desktopAndAbove {
                inset-block-start: 568px;
            }
        }

        @include mobile {
            height: 300px;
        }

        @include tablet {
            height: 256px;
        }

        button {
            margin-block-start: 84px;

            @include mobileAndTablet {
                margin-block-start: 62px;
            }
        }
    }

    &-showLess {
        @include flex($justify: center);
    }

    &-Button {
        font-size: $fs-s;
        color: $color-primary;
        border: 1px solid $color-primary;
        padding: 12px 20px;
        cursor: pointer;
        background: $white;
    }
}
