@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Product {
    padding: 0 50px 0 32px;

    @include mobile {
        margin-block-end: 20px;
    }

    &-PriceSection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 17px;

        @include mobile {
            align-items: center;
        }

        div {
            font-size: $fs-s;
            line-height: 24px;

            &.ProductDiscountLabel {
                width: fit-content;
                margin-inline-start: 32px;
            }
        }
    }

    &-PriceWrapper {
        margin-block-start: 0;
        padding: 0 50px 0 32px;

        &_type {
            &_regularPrice {
                color: $gray;
                text-decoration: line-through;
            }

            &_domneoPrice,
            &_setPrice {
                span {
                    color: $black;
                    font-size: 20px;
                }
            }
        }

        &_through {
            span {
                text-decoration: line-through;
            }
        }

        &_highlighted {
            span {
                color: $red;
                font-size: $fs-xl;
            }
        }

        .AddToCart {
            margin: 15px 0 0;
        }
    }

    &-AddToCartWrapper {
        margin: 15px 0;

        @include above1200 {
            margin-block-end: 20px;
        }

        .Product-ButtonsWrapper {
            display: block;

            .Product-AddToCart {
                --button-background: #{$color-tertiary};
                --button-border-width: 0;
                --button-border-bottom-width: 0;
                --button-color: #000;
                --button-hover-background: #{$color-tertiary};
                --button-hover-color: #000;

                padding: 0 10px;
                width: 100%;
            }
        }
    }

    &-DiscountTitle {
        margin-inline-end: 0;
        font-size: $fs-s;
        color: $red;
        font-weight: $fw-regular;
    }

    &-Reviews {
        margin-block-end: 12px;

        @include flex();

        @include mobile {
            margin-block-end: 12px;
        }

        @include above1200 {
            margin-block-end: 32px;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    &-Tags {
        @include flex($wrap: wrap);

        @include mobile {
            margin-block-end: 12px;
        }

        @include desktop {
            margin-block-end: 0;
        }

        @include above1200 {
            margin-block-end: 24px;
        }
    }

    &-Tag {
        background: $white;
        border: 1px solid $gray-light;
        padding: 5px;
        margin: 0 6px 6px 0;

        &:hover {
            background: $color-primary-lighter;
        }
    }

    &-Hashtag {
        color: $color-primary;
        font-weight: $fw-bold;
        padding-inline-end: 3px;
    }
}
