@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage_ArrangementPage {
    .ProductHeader {
        align-items: center;
    }

    .ProductInformation {
        &-Content {
            @include flex($align: flex-start);

            @include mobileAndTablet {
                flex-direction: column;
            }
        }

        &-Header {
            letter-spacing: -0.04em;
            margin-inline-start: 32px;
            margin-block-end: 40px;

            @include mobile {
                margin-inline-start: 15px;
            }
        }

        &-Description {
            /* stylelint-disable declaration-no-important */
            font-family: 'Inter', sans-serif !important;
            background: $color-primary-lighter;
            width: 33%;
            padding: 64px;
            min-height: 306px;

            @include mobileAndTablet {
                width: 100%;
                padding: 15px 15px 40px;
            }

            @include tablet {
                padding: 16px 32px;
            }

            p {
                line-height: 20px;
                font-size: $fs-xs  !important;
                font-weight: $fw-light  !important;

                @include mobileAndTablet {
                    font-size: $fs-s  !important;
                }
            }

            strong {
                line-height: 20px;
                font-size: $fs-xs  !important;

                @include mobileAndTablet {
                    font-size: $fs-s  !important;
                }
            }

            .ProductInformation-Header {
                margin-inline-start: 0;
                margin-block-end: 22px;
            }

            &::after {
                content: '';
                background: $color-primary-lighter;
                position: absolute;
                height: 100%;
                width: 126px;
                inset-block-start: 0;
                inset-inline-end: -126px;
            }

            >* {
                font-family: 'Inter', sans-serif !important;
            }
        }

        &-Advantages {
            font-family: 'Inter', sans-serif !important;
            border: 1px solid $gray-light;
            margin: 30px 15px;
            padding: 35px 0 15px;
            background: $white;
            width: 31%;
            height: auto;

            @include mobileAndTablet {
                width: calc(100% - 30px);
                margin: -30px 15px 0;
                padding: 15px 0 15px;
            }

            .ProductInformation-Header {
                margin-block-end: 22px;
            }

            ul,
            ol {
                padding-inline-start: 70px;
                padding-inline-end: 36px;
                list-style-type: none;

                @include mobileAndTablet {
                    padding-inline-start: 55px;
                    padding-inline-end: 24px;
                }

                li {
                    line-height: 26px;
                    letter-spacing: -0.02em;
                    font-size: $fs-s  !important;
                    font-weight: $fw-light  !important;

                    &::before {
                        content: '';
                        transform: rotate(-45deg);
                        border: 2px solid $color-primary;
                        border-right: 0;
                        border-top: 0;
                        width: 16px;
                        height: 8px;
                        position: absolute;
                        line-height: 30px;
                        inset-inline-start: -37px;
                        inset-block-start: 5px;
                        padding: 0;
                    }
                }
            }

            >* {
                font-family: 'Inter', sans-serif !important;
            }
        }

        &-Designer {
            background: white;
            width: 36%;
            flex: 1 1;
            padding: 60px 20px;

            @include mobileAndTablet {
                margin-block-start: 20px;
                padding: 15px;
                width: 100%;
            }

            .ProductInformation-Header {
                margin-inline-start: 0;
                margin-block-end: 32px;

                @include mobileAndTablet {
                    margin-block-end: 0;
                }
            }

            &Info {
                margin-block-end: 10px;

                @include flex();
            }

            &Content {
                flex: 1 0;
                margin: 5px 0 0 30px;
                font-weight: $fw-light;

                span {
                    font-size: $fs-s;
                    font-weight: $fw-medium;
                }

                p {
                    margin-block-start: 5px;
                    line-height: 21px;
                    letter-spacing: -1px;
                    max-width: 205px;
                    font-size: $fs-xs;
                }
            }

            &Image {
                flex: 0 0 126px;
                height: 126px;
                width: 126px;
                border-radius: 50%;
                margin: 25px 0;

                @include mobileAndTablet {
                    flex: 0 0 96px;
                    height: 96px;
                    width: 96px;
                }
            }

            a {
                margin-inline-start: 26px;
                color: $color-primary;
                font-size: $fs-xs;

                @include mobileAndTablet {
                    margin-inline-start: 0;
                }
            }
        }
    }

    .ProductPage-Wrapper {
        display: block;

        @include tablet {
            padding-block-end: 32px;
        }
    }

    .ProductReviews {
        &-SummaryDetails {
            @include mobile {
                margin-inline-start: 0;
            }
        }

        &-Summary {
            padding: 0 12px;
        }

        &-LoadMore {
            padding: 0 12px;
        }
    }

    .ProductLinks {
        @include desktop {
            padding-inline: 0;
        }

        &_linkType_related {
            &::before {
                display: none;
            }
        }

        .slick-slide {
            @include desktop {
                padding: 0;
            }
        }

        .RelatedBuiltProducts-Wrapper {
            .slick-arrow {
                inset-block-start: 35%;
            }
        }
    }
}
