@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AmastyBundleProducts {
    width: 100%;
    margin: 0 auto;
    padding-block-start: 16px;
    padding-block-end: 32px;
    padding-inline: 0;

    @include mobileAndTablet {
        padding-inline-start: 0;
        padding-inline-end: 0;
        width: 100%;
    }

    &-Wrapper {
        padding-inline: 0;

        @include flex($wrap: wrap, $align: center, $justify: space-between)
    }

    &-TopWrapper {
        width: 100%;
        font-weight: $fw-regular;
        margin-block-end: 0;
    }

    &-List {
        display: grid;
        grid-gap: 36px;
        grid-template-columns: 1fr 1fr;
        width: calc(100% - 415px);

        &::after {
            background-image: url('src/assets/icons/equal.svg');
            position: absolute;
            inset-block-start: 50%;
            inset-inline-end: -59px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            height: 40px;
            width: 40px;
            content: '';

            @include mobileAndTablet {
                display: none;
            }
        }

        @include mobileAndTablet {
            grid-gap: 0;
            width: 100%;

            @include flex($dir: column, $align: flex-end)
        }

        @include desktopAndAbove {
            grid-template-columns: repeat(3, 1fr);
            row-gap: 59px;
        }

        @include tablet {
            grid-template-columns: repeat(1, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include above1365 {
            grid-gap: 59px;
        }
    }

    &-PriceSection {
        width: calc(100% - 40px);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 4px 1px 0px rgba(0, 0, 0, 0.05), 0px 8px 3px 0px rgba(0, 0, 0, 0.05), 0px 15px 7px 0px rgba(0, 0, 0, 0.05), 0px 28px 14px 0px rgba(0, 0, 0, 0.05), 0px 51px 29px 0px rgba(0, 0, 0, 0.06), 0px 50px 40px 0px rgba(0, 0, 0, 0.07), 4px 24px 80px 0px rgba(0, 0, 0, 0.07);

        @include mobileAndTablet {
            margin-inline-start: 40px;
            padding: 24px 16px;
            background: $white;
            z-index: 10;
        }

        @include desktop {
            width: 332px;
            padding: 40px 16px;
        }

        &:first-of-type {
            @include desktop {
                z-index: 21;
            }
        }

        &::after {
            @include mobileAndTablet {
                background-image: url('src/assets/icons/equal.svg');
                position: absolute;
                inset-block-start: 50%;
                inset-inline-start: -40px;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                height: 32px;
                width: 32px;
                content: '';
                transform: translate(0, -50%)
            }
        }
    }

    &-PriceWrapper {
        @include flex($align: center);

        font-size: $fs-s;
        margin-block-end: 18px;

        &_type {
            &_regularPrice {
                span {
                    color: $gray;
                    font-weight: $fw-light;
                    text-decoration: line-through;
                }
            }

            &_promoPrice {
                span {
                    color: $red;
                    font-size: $fs-xl;
                    font-weight: $fw-medium;
                }
            }

            &_discount {
                color: $red;
                margin-block-end: 24px;
            }
        }

        >div {
            width: 50%;
        }
    }

    &-ButtonsWrapper {
        @include desktopAndAbove {
            gap: 8px;

            @include flex($align: center);
        }

        .AmastyBundleProducts {
            &-AddToCart {
                width: 100%;
                line-height: 20px;
                padding: 0;

                &:hover {
                    padding: 0;
                }
            }

            &-QuickAddToCart {
                width: 100%;
                line-height: 20px;
                padding: 0;

                &:hover {
                    padding: 0;
                }

                @include mobileAndTablet {
                    margin-block-end: 8px;
                }
            }
        }
    }

    &-Redirect {
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 5px;
        padding-block-end: 3px;
        color: $color-primary;
        font-size: $fs-xs;
        font-weight: $fw-medium;
        border-bottom: 1px solid $color-primary;
    }

    .ProductCard {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 4px 1px 0px rgba(0, 0, 0, 0.05), 0px 8px 3px 0px rgba(0, 0, 0, 0.05), 0px 15px 7px 0px rgba(0, 0, 0, 0.05), 0px 28px 14px 0px rgba(0, 0, 0, 0.05), 0px 51px 29px 0px rgba(0, 0, 0, 0.06), 0px 80px 40px 0px rgba(0, 0, 0, 0.07), 4px 24px 80px 0px rgba(0, 0, 0, 0.07);
        height: 100%;
        z-index: 10;

        @include mobileAndTablet {
            width: calc(100% - 40px);
            background: $white;
        }

        @include desktopAndAbove {
            height: 414px;
        }

        &:hover {
            @include desktop {
                border: 1px solid transparent;
            }
        }

        &::after {
            background-image: url('src/assets/icons/plus.svg');
            position: absolute;
            inset-block-start: 50%;
            inset-inline-end: -36px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            height: 32px;
            width: 32px;
            content: '';

            @include mobileAndTablet {
                inset-inline-start: -40px;
                transform: translate(0, -50%)
            }

            @include above1365 {
                inset-inline-end: -48px
            }
        }

        &:last-of-type {
            &::after {
                display: none;

                @include mobileAndTablet {
                    display: block;
                }
            }
        }

        &:first-of-type {
            @include mobileAndTablet {
                width: 100%;
            }

            &::after {
                @include mobileAndTablet {
                    display: none;
                }
            }
        }

        &-ProductActions {
            @include mobileAndTablet {
                display: none;
            }
        }

        &-Reviews {
            display: none;
        }

        &-FigureReview {
            @include mobileAndTablet {
                width: 155px;
            }
        }

        &-Discount {
            @include mobileAndTablet {
                inset-block-end: 12px;
                inset-inline-start: 12px;
            }
        }

        &-Content {
            padding-block-end: 16px;

            @include mobileAndTablet {
                width: calc(100% - 165px);
            }
        }

        &-ContentWrapper {
            @include mobileAndTablet {
                flex-direction: row;
            }

            @include desktopAndAbove {
                justify-content: space-between;
                height: 100%;
            }
        }

        &-DeliveryTime {
            width: 100%;

            &_empty {
                border: 0;
                min-height: 31px;

                @include desktop {
                    min-height: 34px;
                }
            }

            @include mobileAndTablet {
                width: auto;
            }
        }

        &-ProductNameContainer {
            @include mobileAndTablet {
                border: 0;
                margin: 0;
            }
        }

        &-Name {
            @include above1365 {
                height: 96px;
                line-height: 24px;
                -webkit-line-clamp: 4;
            }
        }

        .ProductPrice {
            @include flex($dir: column);

            &-HighPrice {
                order: -1;
                font-size: $fs-xs;
                min-height: 14px;

                @include mobileAndTablet {
                    font-size: $fs-xxs;
                    min-height: 18px;
                }
            }

            &-PriceValue {
                @include mobileAndTablet {
                    font-size: $fs-s;
                }
            }
        }
    }

    .Field-Wrapper {
        position: absolute;
        z-index: 10;
        inset-block-start: 15px;
        inset-inline-start: 15px;

        @include mobileAndTablet {
            inset-block-start: 12px;
            inset-inline-start: 12px;
        }

        .ProductCard {
            &-Checkbox {
                margin: 0;
            }
        }
    }

    .ProductDiscountLabel {
        width: auto;
        min-height: 25px;
        padding: 0 8px;
    }

    &_isHiddenElement {
        height: 300px;
        overflow: hidden;
        padding: 0;
        margin: -16px 0 -72px;

        &~.AmastyBundleProducts {
            display: none;
        }

        @include mobileAndTablet {
            height: 762px;
            margin: 0 0 -36px;
        }
    }

    &_isFirstHiddenElement {
        height: 300px;
        overflow: hidden;
        padding: 0;
        margin: -36px 0 -72px;

        &~.AmastyBundleProducts {
            display: none;
        }

        @include mobileAndTablet {
            height: 762px;
            margin: 0 0 -36px;
        }

        @include desktopAndAbove {
            height: 860px;
        }

        @include above1365 {
            padding: 0 32px;
        }
    }
}
