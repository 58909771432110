@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductDetails {
    &-Wrapper {
        padding: 0;
    }

    &-Heading {
        font-size: $fs-l;
    }

    &-Content {
        width: 100%;

        .ProductLinks-SliderWrapper {
            padding: 40px 0;

            .AdovoxSlider_variant_products {
                @include desktop {
                    overflow: visible;
                }
            }
        }
    }

    &-Section {
        padding: 40px 0;

        @include mobile {
            padding: 20px 0;
        }

        @include above1480 {
            padding: 40px 32px;
        }

        .ProductDetails-Heading {
            margin-block-end: 40px;
        }

        .AmastyRelatedProducts {
            @include desktop {
                padding-inline: 32px;
                margin-inline: -32px;
            }

            &-Wrapper {
                @include desktop {
                    padding-inline: 0;
                }
            }
        }
    }

    &-Navigation {
        transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        position: sticky;
        z-index: 9;
        inset-block-start: 60px;
        background: $gray-light7;

        @include flex($align: center);

        @include mobileAndTablet {
            overflow-x: scroll;
            width: calc(100% + 40px);
            margin: 10px -20px 0;
            font-size: $fs-xs;

            @include flex($align: center, $justify: flex-start);

            &::-webkit-scrollbar {
                display: none;
                -ms-overflow-style: none;
            }
        }

        @include desktop {
            inset-block-start: 205px;
        }

        @include mobile {
            @include flex($dir: column);

            position: relative;
            inset-block-start: 0;
        }
    }

    &-NavigationItem {
        font-size: $fs-s;
        line-height: 150%;
        margin: 0 18px 0 0;
        text-transform: lowercase;

        &:last-child {
            margin-block-end: 0;
        }

        @include mobileAndTablet {
            margin: 0;
        }

        @include mobile {
            width: 100%;
        }

        &::before {
            display: none;
        }

        a {
            font-weight: normal;
            padding: 12px 35px;
            white-space: nowrap;
            border-bottom: 0;
            color: $black2;

            @include flex($align: center);

            &.active {
                font-weight: $fw-semi-bold;
            }

            @include mobile {
                padding: 9px 16px;
                font-size: $fs-xs;
                justify-content: center;
            }

            @include mobileAndTablet {
                max-height: 40px;
                border-radius: 0;
            }

            @include desktop {
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }

    &-Sections {
        @include mobile {
            padding: 0 16px;
        }

        @include tabletAndDesktop {
            padding: 0 32px;
        }

        div[name="product-attributes"] {
            .ProductDetails {
                &-Section {
                    padding-block: 0;

                    @include desktop {
                        padding-block-start: 24px;
                    }
                }
            }

            .ProductAttributes {
                &-Attributes {
                    margin-block-end: 0;
                }

                &-ContentHeading {
                    @include mobile {
                        margin: 0 0 16px;
                    }
                }
            }
        }

        div[name="product_description"] {
            .ProductDetails {
                &-Section {
                    padding: 32px 0 40px;
                }
            }
        }

        div[name="product_amasty_before_related"] {
            .ProductDetails {
                &-Section {
                    padding-block: 0;
                }
            }

            + div[name="product-reviews"] {
                .ProductDetails {
                    &-Section {
                        padding-block-start: 0;
                    }
                }
            }
        }
    }
}
