@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductActions {
    @include mobile {
        margin-block-end: 20px;
    }

    @include tabletAndAbove {
        border: 1px solid $gray-lighter;
        padding: 32px 16px;
        box-shadow: 0 40px 50px rgba(0, 0, 0, 0.07),
            0 51px 29px rgba(0, 0, 0, 0.056),
            0 28px 14px rgba(0, 0, 0, 0.054),
            0 15px 7px rgba(0, 0, 0, 0.054),
            0 8px 4px rgba(0, 0, 0, 0.052),
            0 4px 2px rgba(0, 0, 0, 0.048),
            0 1px 1px rgba(0, 0, 0, 0.035);
    }

    &-Placeholder {
        &_type {
            &_price {
                width: 100%;

                &::after {
                    display: block;
                    height: 122px;
                    width: 100%;

                    @include mobileAndTablet {
                        height: 92px;
                    }
                }
            }
        }
    }

    &-Discount {
        @include flex($align: center, $justify: flex-start);

        @include tablet {
            @include flex($dir: column)
        }

        margin-block-start: 6px;
        width: 100%;

        span {
            width: 50%;

            @include tablet {
                width: 100%;
                margin-block-end: 6px;
            }
        }
    }

    &-DiscountTitle {
        font-size: $fs-s;
        color: $error;
        font-weight: $fw-regular;
        margin-inline-end: 7px;
    }

    &-PriceWrapper {
        margin-block-start: 48px;

        @include mobile {
            margin-block-start: 10px;
        }

        .ProductPrice {
            @include flex($wrap: wrap, $align: center, $justify: flex-start);

            .ProductPrice-HighPrice {
                margin-inline-start: 7px;
            }
        }
    }

    &-LowestPrice,
    &-PromoPrice,
    &-RegularPrice {
        font-weight: $fw-regular;
        font-size: $fs-s;
        line-height: 24px;
        margin: 8px 0;
        width: 100%;

        span {
            display: inline-block;
            width: 50%;
            margin: 0;

            @include tablet {
                display: block;
                width: 100%;
            }
        }

        .ProductPrice {
            &-Qmeters {
                width: auto;
                display: inline-block;
                padding-inline-start: 2px;

                sup {
                    font-size: 60%;
                    inset-block-start: -0.5em;
                }
            }
        }
    }

    &-LowestPrice {
        margin-block-start: 16px;
        font-size: $fs-xs;
        font-weight: $fw-light;
        line-height: 24px;

        span {
            width: auto;
        }
    }

    &-RegularPrice {
        order: -1;
    }

    &-PromoPrice {
        ins {
            font-size: $fs-xl;
            margin-inline-start: 7px;

            @include mobile {
                font-size: $fs-m;
            }
        }
    }

    &-Schema {
        @include mobile {
            width: 100%;
        }
    }

    &-AddToCartWrapper {
        box-shadow: none;
        margin-block-start: 48px;

        @include flex($wrap: wrap);

        @include mobile {
            margin: 24px 0 0;
        }

        .ProductActions {
            &-ButtonsWrapper {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                margin-block-end: 24px;

                .ProductActions {
                    &-AddToCart {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                }

                .Button {
                    @include mobile {
                        min-width: unset;
                    }

                    &_isHollow {
                        span {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }

    &-Quantity {
        @include flex($wrap: wrap, $align: center, $justify: flex-start);

        width: 100%;
        margin-block-end: 20px;

        @include tablet {
            flex-direction: column;
            align-items: flex-start;
        }

        .Field {
            @include mobile {
                margin-block-start: 5px;
            }

            &-Wrapper {
                width: 50%;
            }

            &-InputWrapper {
                @include flex($align: center, $justify: flex-start);
            }

            button {
                border: 0;
            }

            input {
                border: 2px solid $gray-light;
                height: 44px;
                font-weight: $fw-light;
            }

            .ValidIcon {
                display: none;
            }
        }
    }

    &-QuantityTitle {
        width: 40%;
        font-weight: $fw-regular;
        font-size: $fs-s;
        line-height: 24px;

        @include mobile {
            width: auto;
            margin-inline-end: 10px;
        }
    }

    &-ActionsWrapper {
        &:nth-child(3) {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 0;
            }
        }
    }

    &-CheapestDelivery {
        @include flex($align: center);

        font-weight: $fw-regular;
        font-size: $fs-s;
        line-height: 24px;
        margin-block-start: 16px;


        span {
            width: 50%;
        }
    }

    &-FreeDelivery {
        @include flex($align: center);

        font-weight: $fw-regular;
        font-size: $fs-s;
        line-height: 24px;
        margin-block-start: 16px;
        color: $green;
    }

    &-Availability {
        margin-block-start: 16px;

        .ProductStockAvailability {
            &-Wrapper {
                @include flex($align: flex-start, $wrap: wrap);

                span {
                    width: 50%;
                }

                .ProductStockAvailability {
                    &-OnRequest {
                        color: $black;
                        font-weight: $fw-regular;
                    }

                    &-Value {
                        width: auto;

                        &_days {
                            color: $black;
                        }
                    }

                    &-QuantityWrapper {
                        width: auto;
                        color: $black;
                        padding-inline-start: 9px;

                        sup {
                            font-size: 60%;
                            inset-block-start: -0.5em;
                        }
                    }

                    &-LabelWrapper {
                        width: auto;
                        padding-inline-end: 3px;
                    }
                }
            }

            &-SubWrapper {
                @include flex($wrap: wrap);
            }

            span {
                font-weight: $fw-regular;
                font-size: $fs-s;
                line-height: 24px;

                &:first-child {
                    color: $black;
                }
            }

            b {
                color: $black;
            }
        }
    }

    &-Calculator {
        display: grid;
        grid-template-columns: repeat(3, 33.33%);
        align-items: stretch;
        width: 100%;
        border: 1px solid $gray-light;
        border-right: 0;
        background: $gray-lighter;
        margin-block: 0;

        @include tabletAndNarrowDesktop {
            @include flex($dir: column)
        }

        &_glue {
            grid-template-columns: repeat(2, 50%);
        }

        &Wrapper {
            margin-block-end: 16px;
            width: 100%;

            &:first-child {
                @include desktopAndAbove {
                    margin-block-start: -32px;
                }
            }
        }

        &Heading {
            margin-block: 0 8px;
            font-weight: $fw-regular;
            font-size: $fs-s;
            line-height: 24px;
        }

        &Result {
            margin-block: 4px 0;
            font-weight: $fw-regular;
            font-size: $fs-xxs;
            line-height: 24px;

            sup {
                font-size: 60%;
                inset-block-start: -0.5em;
            }
        }

        &Box {
            @include flex($dir: column, $align: center, $justify: center);

            padding-inline: 8px;
            padding-block: 8px;
            text-align: center;
            font-weight: $fw-regular;
            font-size: $fs-s;
            border-right: 1px solid $gray-light;

            @include tabletAndNarrowDesktop {
                width: 100%;

                &_width,
                &_area {
                    order: 1;
                }

                &_length,
                &_changer {
                    order: 3;
                }

                &_supply,
                &_calculated {
                    order: 5;
                }

                &_widthName,
                &_meters {
                    order: 2;
                    border-bottom: 1px solid $gray-light;
                }

                &_lengthName,
                &_quantity {
                    order: 4;
                    border-bottom: 1px solid $gray-light;
                }

                &_supplyName,
                &_finalValue {
                    order: 6;
                }
            }

            &_separate {
                border-bottom: 1px solid $gray-light;
            }

            &_label {
                text-transform: lowercase;
                padding-block-start: 0;
                font-size: $fs-xxs;
                white-space: nowrap;

                sup {
                    font-size: 60%;
                    inset-block-start: -0.5em;
                }

                &.ProductActions-CalculatorBox_field {
                    background: $white;
                }
            }

            &_value {
                padding-block-end: 0;
                font-weight: $fw-bold;
                line-height: 24px;
                text-align: center;

                &.ProductActions-CalculatorBox_field {
                    background: $white;
                    padding-inline: 0;
                }
            }

            .Field-Wrapper {
                height: 24px;
                width: 100%;

                & .Field {
                    margin-inline: 0;
                    margin-block: 0;
                    height: 100%;
                    width: 100%;

                    &-ErrorMessages {
                        @include visually-hidden;
                    }

                    &_hasError input {
                        border-width: 1px;
                    }

                    .ValidIcon,
                    .ErrorIcon {
                        @include visually-hidden;
                    }

                    &_type {
                        &_select,
                        &_text {
                            .Field {
                                &-InputWrapper {
                                    height: 100%;

                                    select,
                                    input {
                                        height: 100%;
                                        width: 100%;
                                        padding-inline: 8px;
                                        padding-block: 0;
                                        border: 0;
                                        font-weight: $fw-bold;
                                        text-align: center;
                                        color: var(--input-color);
                                    }
                                }
                            }
                        }

                        &_number {
                            .Field {
                                &-InputWrapper {
                                    @include flex($align: stretch, $justify: center);

                                    padding-inline: 8px;
                                    height: 100%;
                                    width: 100%;

                                    [type='number'] {
                                        height: auto;
                                        width: 100%;

                                        &~button {
                                            height: auto;
                                            background: $white;
                                            border: 0;
                                            width: 32px;

                                            &[disabled] {
                                                opacity: inherit;

                                                .AddIcon_isPrimary,
                                                .MinusIcon_isPrimary {
                                                    fill: $gray-light;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &_isProductWithCalculator {
        .ProductActions {
            &-PromoPrice {
                ins {
                    font-size: $fs-s;
                    width: auto;

                    @include mobile {
                        font-size: $fs-m;
                    }
                }

                .ProductPrice {
                    &-PriceValue {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-GroupedItems {
        >ul {
            @include mobile {
                padding: 0 16px;
            }
        }
    }

    &-AskAboutProduct {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 16px 0 20px;

        @include desktop {
            grid-template-columns: 1fr;
            margin: 12px 0;
        }
    }

    &-AskAboutProductLink,
    &-AskAboutProductButton {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-inline: 24px;
        width: 100%;
        cursor: pointer;

        @include desktop {
            justify-content: center;
            padding-inline: 0;
        }

        span {
            color: $color-primary;
            font-size: $fs-s;
            line-height: 24px;
        }
    }

    &-InstalmentsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: $color-primary-lighter;
        padding: 4px 0;
        margin: 16px 0;

        @include tablet {
            justify-content: flex-start;
        }

        span {
            font-size: 16px;
            line-height: 24px;
            color: $color-primary;

            &:first-of-type {
                @include tablet {
                    width: 100%;
                    padding-inline-start: 4px
                }
            }

            &:last-of-type {
                padding-inline-start: 4px;
            }
        }
    }

    &-InstalmentsData {
        font-weight: 700;
        padding: 0 4px;
    }

    &-InstalmentsCalculator {
        font-size: 16px;
        color: $color-primary;
        text-decoration: underline;
        margin-inline-start: 12px;
    }
}
