@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage {
    overflow-x: hidden;

    @include mobile {
        margin: 20px 0 0;
    }

    @include tablet {
        padding-block-end: 120px;
    }

    @include desktop {
        padding-block-end: 80px;
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
    }

    &-Wrapper {
        padding-block-start: 0;

        @include tabletAndAbove {
            grid-template-columns: 40% 36% 24%;
            grid-gap: 0;
        }

        @include above1365 {
            grid-template-columns: 40% 34% 26%;
        }

        h1 {
            @include mobile {
                margin: 0 16px 12px;
            }
        }

        .ProductActions {
            grid-area: unset;

            @include mobile {
                margin: 0 16px 20px;
            }

            @include tabletAndAbove {
                grid-column: 3;
            }
        }

        .ProductAdditionalInfo {
            &-Wrapper {
                @include mobile {
                    order: 3;
                    margin-inline-start: 16px;
                    margin-inline-end: 16px;
                }

                @include tabletAndAbove {
                    padding: 0 20px 20px 40px;
                }
            }
        }

        .ProductAttributes {
            &-Content {
                @include tablet {
                    min-height: 290px;
                }

                @include desktopAndAbove {
                    min-height: 275px;
                }

                .ProductAttributes-AttributeBlock,
                .ProductAttributes-Attributes {
                    @include mobile {
                        display: none;
                    }
                }

                &.isActive {
                    @include tabletAndAbove {
                        height: auto;
                    }

                    .ProductAttributes-AttributeBlock,
                    .ProductAttributes-Attributes {
                        @include mobile {
                            display: grid;
                        }
                    }

                    .ProductAttributes-ContentHeading {
                        @include mobile {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            &-ContentHeading {
                font-size: $fs-s;
                font-weight: $fw-regular;
                line-height: 24px;
                margin: 0 0 15px;

                @include mobile {
                    margin: 16px 0;
                    cursor: pointer;

                    &::after {
                        content: '';
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-image: url('src/assets/icons/angle-down.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-color: transparent;
                        background-size: contain;
                        transform: rotate(0deg);
                        position: absolute;
                        inset-inline-end: 0;
                        inset-block-start: 0;
                    }
                }
            }

            &-Attributes {
                width: 90%;
                margin-block-end: 0;
                margin-inline-start: 0;
                grid-template-columns: minmax(20px, 165px) 1fr;

                @include tablet {
                    @include flex($dir: column);
                }

                dt,
                dd {
                    background: $white;
                    justify-content: flex-start;
                    padding: 4px 8px;
                    line-height: 21px;
                    font-size: $fs-xs;
                    white-space: break-spaces;

                    @include mobile {
                        padding: 5px 0;
                    }

                    @include tablet {
                        padding-inline-start: 0;
                    }
                }
            }

            &-ShowAllButton {
                width: 100%;
                border: 0;
                cursor: pointer;
                margin-block-start: 12px;
                text-decoration: underline;
            }
        }
    }

    &-Placeholder {
        &_type {
            &_gallery {
                &::after {
                    display: block;
                    height: 400px;
                    width: 100%;

                    @include mobileAndTablet {
                        margin-block-end: 17px;
                    }
                }
            }
        }
    }

    .ProductDetails {
        &-Heading {
            font-size: $fs-xxl;

            @include mobile {
                font-size: $fs-l;
            }
        }
    }

    .ProductInformation {
        &-Wrapper {
            padding-block-end: 0;
        }

        &-ShortDescription {
            margin-block-end: 0;
        }
    }

    .ProductGallery {
        .ProductLabel {
            margin: 10px 0 0 65px;

            &-Text {
                padding: 0 8px;
                height: 23px;
                line-height: 23px;

                @include mobile {
                    height: 20px;
                    line-height: 20px;
                }
            }

            @include mobile {
                margin: 12px 0 0 15px;
            }
        }

        .VideoThumbnail-Thumbnail {
            padding: 0;
        }
    }

    .ProductAttachment {
        padding: 4px 0;

        &-Link {
            font-size: $fs-s;
            display: inline-flex;
            align-items: center;

            &:hover {
                color: $gray-light;
            }
        }

        &-Name {
            padding-inline-start: 8px;
        }
    }

    &~.SocialProofList {
        display: block;

        @include desktop {
            @include flex($dir: column, $justify: center, $align: flex-end);
        }
    }

    .CmsBlock {
        &-Wrapper {
            &_type_pdpBannerDesktop {
                width: 100%;
                grid-column: 1/-1;
                padding-block-end: 24px;
            }

            &_type_pdpBannerMobile {
                padding-block-end: 28px;
            }
        }

        &_type_pdpBannerDesktopSecond {
            padding-block-end: 60px;

            figure {
                padding-block-end: 12px;
            }
        }

        &_type_pdpBannerMobileSecond {
            figure {
                padding-block-end: 12px;
            }
        }
    }

    .DiscountCode {
        &-Wrapper {
            @include flex($align: center);

            background: $gold50;
            padding: 24px;

            @include mobile {
                flex-wrap: wrap;
                align-items: flex-start;
                margin: 0 -16px;
            }
        }

        &-TextWrapper {
            padding-inline: 12px;

            @include mobile {
                width: calc(100% - 60px);
                padding: 0;
            }
        }

        &-Icon {
            @include mobile {
                width: 60px;
            }

            >svg {
                @include mobile {
                    width: 48px;
                }
            }
        }

        &-Title {
            color: $color-primary;
            font-size: $fs-xl;
            line-height: 28px;
            margin-block-end: 12px;
        }

        &-Description {
            font-size: $fs-xs;
            font-weight: $fw-light;
            line-height: 20px;
            margin-block-end: 0;
        }

        &-Box {
            @include flex($align: center);

            @include mobile {
                flex-direction: column;
            }
        }

        &-CodeWrapper {
            box-shadow: 0 111px 70px rgba(0, 0, 0, 0.02),
                0 50px 28px rgba(0, 0, 0, 0.05), 0 27px 13px rgba(0, 0, 0, 0.05),
                0 15px 7px rgba(0, 0, 0, 0.05), 0 8px 3px rgba(0, 0, 0, 0.05),
                0 4px 1px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.03);
            background: $white;
            padding: 24px;
            width: 33%;
            margin-inline-start: auto;

            @include flex($align: center);

            @include mobile {
                width: calc(100% - 60px);
                margin: 16px 0;
            }

            @include above1200 {
                width: 25%;
            }

            button {
                margin-inline-start: auto;
                cursor: pointer;
            }
        }

        &-CodeLabel {
            font-size: $fs-xs;
            font-weight: $fw-regular;
            line-height: 20px;
            margin-block-end: 0;
        }

        &-Code {
            color: $red;
            font-weight: $fw-bold;
            font-size: $fs-s;
            line-height: 24px;
            padding-inline-start: 8px;

            @include mobile {
                padding-inline-start: 0;
            }
        }
    }

    &-DetailsLoader {
        padding: 0 32px;

        @include mobile {
            padding: 0 16px;
        }

        .TextPlaceholder {
            &::after {
                display: block;
                height: 48px;
            }
        }
    }
}
