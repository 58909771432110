@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage_ArrangementPage {
    .ProductActions {
        padding-inline: 15px;
        box-shadow: none;
        border: 0;

        @include tabletAndAbove {
            padding: 0 50px 0 32px;
        }

        @include mobile {
            margin-block-end: 20px;
            padding: 0;
        }

        &-PriceSection {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 17px;

            div {
                font-size: $fs-s;
                line-height: 24px;
            }
        }

        &-AddToCartWrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: 32px -32px;
            align-items: center;

            @include mobile {
                @include flex($justify: center, $align: center, $dir: column);

                margin: 32px 0;
            }

            >div {
                @include flex($justify: center, $align: center, $dir: column);

                @include mobile {
                    width: 100%;
                    padding: 0 16px;
                    align-items: flex-start;
                }
            }

            .CmsBlock-Wrapper {
                @include mobile {
                    order: 1;
                    margin-block-start: 40px;
                    padding: 0;
                }

                >div {
                    width: 100%;
                }
            }

            .ProductActions {
                &-ButtonsWrapper {
                    display: block;
                    margin-block-start: 32px;

                    @include desktop {
                        padding: 0 32px;
                    }

                    .AddToCart {
                        width: 100%;
                    }
                }

                &-ContentWrapper {
                    @include mobile {
                        width: 100%;
                    }
                }

                &-Heading {
                    color: $color-primary;

                    @include mobile {
                        margin-block-end: 30px;
                    }

                    @include desktop {
                        padding: 0 32px;
                    }
                }

                &-PriceWrapper {
                    @include mobile {
                        margin-block-start: 12px;
                    }
                }
            }

            .Product {
                &-PriceWrapper {
                    @include mobile {
                        padding: 0;
                    }
                }

                &-PriceSection {
                    .ProductDiscountLabel {
                        @include mobile {
                            margin: 0;
                        }
                    }
                }
            }
        }

        &-PriceWrapper {
            @include flex($align: center);

            margin-block-start: 0;
            color: $color-primary;
            font-size: $fs-s;
            line-height: 24px;

            @include desktop {
                margin-block-start: 32px;
            }

            &_type {
                &_regularPrice {
                    color: $gray;
                    text-decoration: line-through;
                }

                &_domneoPrice,
                &_setPrice {
                    span {
                        color: $black;
                        font-size: 20px;
                    }
                }
            }

            &_highlighted {
                span {
                    color: $red;
                    font-size: $fs-xl;
                }
            }

            span {
                padding: 0 7px 0 5px;
            }
        }

        &-Discount {
            .ProductActions-DiscountTitle {
                margin-inline-end: 0;
            }
        }

        &-Tags {
            @include flex($wrap: wrap);
        }

        &-Tag {
            background: $white;
            border: 1px solid $gray-light;
            padding: 5px;
            margin: 0 6px 6px 0;

            &:hover {
                background: $color-primary-lighter;
            }
        }

        &-Hashtag {
            color: $color-primary;
            font-weight: $fw-bold;
            padding-inline-end: 3px;
        }

        +.ProductReviews {
            margin-block-start: 32px;
        }
    }
}
