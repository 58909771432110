@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLinks {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding-block-start: 48px;
    padding-block-end: 0;
    padding-inline: 20px;
    z-index: 2;

    @include desktop {
        padding-block-end: 96px;
        padding-block-start: 24px;
    }

    @include tabletAndDesktop {
        padding-inline: 32px;
    }

    @include above1480 {
        padding-inline: 32px;
    }

    +.ProductLinks {
        z-index: 1;

        @include desktop {
            margin-block-start: -120px;
        }
    }

    +.AmastyRelatedProducts {
        @include desktop {
            margin-block-start: -120px;
        }
    }

    @include mobile {
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        width: 100%;
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Wrapper {
        @include desktop {
            padding-inline: 0;
        }

        .ProductLinks {
            &-Card {
                &_hideVariants {
                    border: 1px solid transparent;

                    &:hover {
                        @include tabletAndAbove {
                            border: solid 1px $gray-light;
                        }
                    }
                }

                .ProductCard {
                    &-VisibleOnHover {
                        inset-inline-start: -1px;
                        padding-inline-start: 15px;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .slick-slider {
        margin-inline: -10px;

        @include mobile {
            margin-inline-start: -8px;
            margin-inline-end: -8px;
        }

        @include tabletAndDesktop {
            margin-inline: -22px;
        }
    }

    .slick-list {
        @include tabletAndAbove {
            margin-block-end: -100px;
            padding-block-end: 100px;
        }
    }

    .slick-slide {
        padding: 0 8px;
    }

    .SliderNext,
    .SliderPrev {
        opacity: 0;
    }

    .SliderNext {
        inset-inline-end: -10px;
    }

    .SliderPrev {
        inset-inline-start: -10px;
    }

    &:hover {

        /* stylelint-disable-next-line */
        .SliderNext,
        .SliderPrev {
            opacity: 1;
        }
    }

    .ProductCard {
        &-ProductActions {
            @include mobile {
                display: none;
            }
        }
    }

    &-Title {
        font-weight: $fw-regular;
        margin-block-end: 0;
    }

    .SliderCounterNav {
        margin-block-end: 18px;
    }

    &_linkType_related {
        &::before {
            background: $color-primary-lighter;
            content: '';
            position: absolute;
            width: 100%;
            inset-inline-start: 0;
            height: 323px;
            inset-block-start: 0;
            z-index: -1;

            @include mobile {
                inset-block-start: -16px;
            }
        }
    }
}
