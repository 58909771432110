@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage_VirtualProductPage {
    .ProductHeader {
        align-items: center;

        &-ActionButtons {
            display: none;
        }
    }

    .ProductGallery {
        @include mobile {
            padding: 0 15px;
        }

        &-SliderWrapper {
            @include mobile {
                margin: 0;
                padding: 0;
            }

            overflow: hidden;
        }

        &-Additional {
            @include mobile {
                padding-inline-start: 0;
            }
        }

        .ProductLabel {
            margin: 0;
            color: white;
            text-transform: uppercase;
            position: absolute;
            inset-block-start: -143px;
            inset-inline-start: -143px;
            z-index: 2;
            transform: rotate(-45deg);
            height: 260px;
            width: 260px;
            padding: 0;

            @include tabletAndAbove {
                height: 270px;
                width: 270px;
            }

            &-Text {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                text-align: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                flex-wrap: wrap;
                padding: 0 100px 8px;
                text-transform: none;

                @include tabletAndAbove {
                    padding-block-end: 16px;
                }
            }
        }
    }

    .ProductActions {
        width: 100%;

        @include mobile {
            margin: 0;
            padding: 0 16px;
        }

        @include desktopAndAbove {
            width: 33%;
        }
    }

    .ProductInformation {
        &-Content {
            @include flex($align: flex-start);

            @include mobileAndTablet {
                flex-direction: column;
            }
        }

        &-Header {
            letter-spacing: -0.04em;
            margin-inline-start: 32px;
            margin-block-end: 40px;

            @include mobile {
                margin-inline-start: 15px;
            }
        }

        &-Description {
            /* stylelint-disable declaration-no-important */
            font-family: 'Inter', sans-serif !important;
            padding: 64px;

            p {
                line-height: 20px;
                font-size: $fs-xs !important;
                font-weight: $fw-light !important;

                @include mobileAndTablet {
                    font-size: $fs-s !important;
                }
            }

            strong {
                line-height: 20px;
                font-size: $fs-xs !important;

                @include mobileAndTablet {
                    font-size: $fs-s !important;
                }
            }

            >* {
                font-family: 'Inter', sans-serif !important;
            }
        }
    }

    .ProductPage-Wrapper {
        display: block;

        @include tablet {
            padding-block-end: 32px;
        }
    }

    .ProductReviews {
        &-SummaryDetails {
            @include mobile {
                margin-inline-start: 0;
            }
        }

        &-Summary {
            padding: 0 16px;

            @include desktopAndAbove {
                padding: 0 32px;
            }
        }

        &-LoadMore {
            padding: 0 12px;
        }
    }

    .ProductLinks {
        &_linkType_related {
            &::before {
                display: none;
            }
        }

        .slick-slide {
            @include desktop {
                padding: 0;
            }
        }

        .RelatedBuiltProducts-Wrapper {
            .slick-arrow {
                inset-block-start: 35%;
            }
        }
    }

    .ProductPageBlogPosts {
        padding: 64px 16px 0;

        @include desktopAndAbove {
            padding: 100px 64px 0;
        }
    }
}
