@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage_ProductSetPage {
    display: block;

    .ProductGallery {
        &-Additional {
            @include desktop {
                margin-block-start: 48px;
            }
        }

        .Product {
            &-PriceWrapper {
                width: 100%;
            }

            &-ButtonsWrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
                padding: 24px 0;

                .Button_isHollow {
                    span {
                        color: $color-primary;
                    }
                }
            }

            &-AskAboutProduct {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 16px 0 20px;

                @include desktop {
                    grid-template-columns: 1fr;
                    margin: 12px 0;
                }
            }

            &-AskAboutProductLink,
            &-AskAboutProductButton {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-inline: 24px;
                width: 100%;
                cursor: pointer;

                @include desktop {
                    justify-content: center;
                    padding-inline: 0;
                }

                span {
                    color: $color-primary;
                    font-size: $fs-s;
                    line-height: 24px;
                }
            }

            &-InstalmentsWrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: $color-primary-lighter;
                padding: 4px 0;
                margin: 16px 0;

                @include tablet {
                    justify-content: flex-start;
                }

                span {
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-primary;

                    &:first-of-type {
                        @include tablet {
                            width: 100%;
                            padding-inline-start: 4px
                        }
                    }

                    &:last-of-type {
                        padding-inline-start: 4px;
                    }
                }
            }

            &-InstalmentsData {
                font-weight: 700;
                padding: 0 4px;
            }

            &-InstalmentsCalculator {
                font-size: 16px;
                color: $color-primary;
                text-decoration: underline;
                margin-inline-start: 12px;
            }
        }

        >.Product-PriceWrapper {
            @include desktop {
                padding: 32px 16px;
                width: 30%;
                margin-inline-start: 3%;
                border: 1px solid #EEEEEE;
                box-shadow: 0 40px 50px rgba(0, 0, 0, 0.07), 0 51px 29px rgba(0, 0, 0, 0.056), 0 28px 14px rgba(0, 0, 0, 0.054), 0 15px 7px rgba(0, 0, 0, 0.054), 0 8px 4px rgba(0, 0, 0, 0.052), 0 4px 2px rgba(0, 0, 0, 0.048), 0 1px 1px rgba(0, 0, 0, 0.035);
            }
        }
    }

    .AskAboutProduct {
        padding-inline: 12px;
        padding-block: 20px;

        @include desktop {
            padding-block: 40px;
        }
    }

    .ProductReviews {
        padding-block: 20px;

        @include desktop {
            padding-block: 40px;
        }
    }
}
