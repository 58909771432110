@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductVariants {
    &-Wrapper {
        margin-block-start: 0;

        @include mobile {
            margin-block-end: 24px;
        }

        @include tabletAndAbove {
            margin-block-start: 24px;
        }

        &::before {
            @include tabletAndAbove {
                content: '';
                position: absolute;
                height: 1px;
                background-color: $gray-light;
                width: 75%;
                inset-block-start: -16px;
                inset-inline-start: 8px;
            }

            @include tablet {
                inset-inline-start: 0;
            }
        }
    }

    &-Title {
        @include mobile {
            font-size: $fs-s;
        }
    }

    &-SubWrapper {
        @include flex($dir: column, $wrap: wrap);

        margin-block-end: 16px;

        @include mobileAndTablet {
            @include flex($wrap: wrap)
        }
    }

    &-Options {
        @include flex($align: center, $wrap: wrap);

        overflow: hidden;
        padding: 1px;

        @include mobileAndTablet {
            @include flex($wrap: wrap)
        }

        &.expanded {
            height: 80px;

            +.ProductVariants-ToggleButton {
                @include flex($justify: center, $align: center);
            }
        }

        &.expanded.isActive {
            height: auto;

            +.ProductVariants-ToggleButton {
                .AddIcon {
                    display: none;
                }

                .MinusIcon {
                    display: block;
                }
            }
        }
    }

    &-OptionTitle {
        font-size: $fs-xs;
        line-height: 20px;
        margin-block-end: 8px;

        @include mobileAndTablet {
            margin-block-end: 6px;
            width: 100%;
        }
    }


    &-Option {
        padding: 0px 15px;
        outline: 1px solid $gray-light;
        font-weight: $fw-light;
        font-size: $fs-xs;
        border: 1px solid transparent;
        margin-block-end: 8px;
        margin-inline-end: 8px;
        height: 32px;
        display: -webkit-box;
        overflow: hidden;
        line-height: 32px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &_isActive {
            outline: 1px solid $color-primary;
            border: 1px solid $white;
            cursor: default;
        }

        &_isSwatch {
            height: 32px;
            width: 32px;
            margin-inline-end: 8px;
            padding: 0;

            @include flex($justify: center, $align: center);
        }
    }

    &-ToggleButton {
        display: none;
        height: 32px;
        width: 32px;
        border: 1px solid $gray-light;
        font-weight: $fw-light;
        font-size: $fs-xs;
        cursor: pointer;

        @include mobileAndTablet {
            margin: 0 0 5px;
        }

        @include mobileAndTablet {
            margin: 0 0 5px;
        }

        .AddIcon,
        .MinusIcon {
            fill: $gray-light;
            pointer-events: none;
        }

        .MinusIcon {
            display: none;
        }
    }
}
