@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AmastyRelatedProducts {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline: 0;

    @include desktop {
        padding-block-end: 96px;
    }

    @include mobile {
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        padding-block-end: 32px;
        width: 100%;
    }

    @include tabletAndDesktop {
        padding-inline: 32px;
    }

    @include above1480 {
        padding-inline: 32px;
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include desktopAndAbove {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Wrapper {
        @include mobile {
            padding: 0;
        }

        @include desktop {
            padding: 0;
        }

        .AmastyRelatedProducts {
            &-Card {
                &_hideVariants {
                    border: 1px solid transparent;

                    &:hover {
                        @include tabletAndAbove {
                            border: solid 1px $gray-light;
                        }
                    }
                }

                .ProductCard {
                    &-VisibleOnHover {
                        inset-inline-start: -1px;
                        padding-inline-start: 15px;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .slick-slider {
        margin-inline-start: -22px;
        margin-inline-end: -22px;

        @include mobile {
            margin-inline-start: -8px;
            margin-inline-end: -8px;
        }
    }

    .slick-list {
        @include tabletAndAbove {
            margin-block-end: -100px;
            padding-block-end: 100px;
        }

        @include desktop {
            margin-inline: 18px;
        }
    }

    .slick-slide {
        padding: 0 8px;
    }

    .SliderNext,
    .SliderPrev {
        opacity: 0;
    }

    .SliderNext {
        inset-inline-end: -10px;
    }

    .SliderPrev {
        inset-inline-start: -10px;
    }

    &:hover {

        /* stylelint-disable-next-line */
        .SliderNext,
        .SliderPrev {
            opacity: 1;
        }
    }

    .ProductCard {
        &-ProductActions {
            @include mobile {
                display: none;
            }
        }
    }

    &-TopWrapper {
        font-weight: $fw-regular;
        margin-block-end: 0;
    }

    .SliderCounterNav {
        margin-block-end: 18px;
    }
}
